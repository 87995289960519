import React from "react"
import PropTypes from "prop-types"

const EmbedSlice = ({ slice }) => {
  return (
    <div className="container">
      <div className="has-text-centered">
        <div className="figure image">
          <div dangerouslySetInnerHTML={{ __html: slice.embed.html }}></div>
        </div>
      </div>
    </div>
  )
}

export default EmbedSlice

EmbedSlice.propTypes = {
  slice: PropTypes.object.isRequired,
}
