import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Image from "../components/image"
import { css } from "@emotion/core"
import { colors } from "../styles/styles"
import { RichText, Date } from "prismic-reactjs"
import SliceRichText from "../slices/richtext"
import SliceQuote from "../slices/quote"
import SliceSection from "../slices/section"
import SliceImage from "../slices/image"
import SliceEmbed from "../slices/embed"
import LocalizedLink from "../components/localizedLink"
import slugify from "slugify"
import Card from "../components/card"
import Newsletter from "../components/newsletter"
import { ArrowScrollToTop } from "../components/arrowScrollToTop"

function BlogPost({ data, pageContext: { locale, uid }, location }) {
  const postData = data.prismic.blogpost
  const blogPageData = data.prismic.allBlogpages.edges[0].node
  // const timestamp = Date(postData._meta.firstPublicationDate)
  const formattedDate = date =>
    Intl.DateTimeFormat(locale, {
      year: "numeric",
      month: "long",
      day: "2-digit",
    }).format(date)

  return (
    <>
      <SEO
        title={postData.meta_title}
        desc={postData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        location={location}
        pathname={location.pathname}
        article
        node={postData}
      />

      <div css={blogPostCss}>
        <section className="section blog__header">
          <div className="container">
            <div className="columns is-centered is-multiline">
              <article className="column is-8 has-text-centered">
                <h1 className="title is-3">
                  {RichText.asText(postData.title)}
                </h1>
                <p className="has-text-weight-light">
                  {formattedDate(
                    Date(
                      postData.post_date || postData._meta.firstPublicationDate
                    )
                  )}
                  {/* {postData._meta.firstPublicationDate !==
                    postData._meta.lastPublicationDate && (
                    <small>
                      updated on{" "}
                      {formattedDate(Date(postData._meta.lastPublicationDate))}
                    </small>
                  )} */}
                </p>

                <div className="tags is-centered">
                  {postData._meta.tags.map((tag, i) => (
                    <LocalizedLink
                      key={i}
                      to={`blog/tag/${slugify(tag)}#jumpToTag`}
                    >
                      <span className="tag is-rounded has-background-white has-text-primary has-text-weight-medium button__gradient-border mx-4 mt-4">
                        {tag}
                      </span>
                    </LocalizedLink>
                  ))}
                </div>

                <div className="image">
                  <Image
                    sharp={postData.main_imageSharp}
                    image={postData.main_image}
                  />
                </div>
              </article>
            </div>
          </div>
        </section>

        <section className="section blog__main">
          <div className="container">
            <div className="columns is-centered is-multiline">
              <article className="column is-8 is-theme2 blog__main-content">
                <div className="social__share">
                  <p>{blogPageData.share}</p>
                  <ul>
                    <li>
                      <a
                        href={`https://twitter.com/intent/tweet?text=${RichText.asText(
                          postData.title
                        )}&url=${location.href}`}
                        data-show-count="false"
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                      >
                        <span className="icon-twitter"></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${location.href}`}
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                      >
                        <span className="icon-linkedin"></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                      >
                        <span className="icon-facebook"></span>
                      </a>
                    </li>
                  </ul>
                </div>

                {postData.content && (
                  <SliceRichText slice={{ text: postData.content }} />
                )}
                {postData.body &&
                  postData.body.map((slice, i) => {
                    switch (slice.__typename) {
                      case "PRISMIC_BlogpostBodyEmbed":
                        return <SliceEmbed key={i} slice={slice.primary} />
                      case "PRISMIC_BlogpostBodyText":
                        return <SliceRichText key={i} slice={slice.primary} />
                      case "PRISMIC_BlogpostBodyQuote":
                        return (
                          <SliceQuote
                            key={i}
                            slice={slice.primary}
                          ></SliceQuote>
                        )
                      case "PRISMIC_BlogpostBodyBanner_with_caption":
                        return <SliceSection key={i} slice={slice.primary} />
                      case "PRISMIC_BlogpostBodyImage":
                        return <SliceImage key={i} slice={slice.primary} />
                      default:
                        return null
                    }
                  })}
                <div></div>
              </article>
            </div>
          </div>
        </section>
        {postData.related_posts &&
          postData.related_posts[0].related_post !== null && (
            <section className="section blog__related-posts">
              <div className="container">
                <h3 className="title is-4 has-text-centered has-text-weight-normal">
                  {RichText.asText(blogPageData.related_posts)}
                </h3>
                <div className="columns is-multiline">
                  {postData.related_posts.map(related_post => (
                    <div
                      key={related_post.related_post._meta.uid}
                      className="column is-4 blog__thumbnail"
                    >
                      <Card postData={{ node: related_post.related_post }} />
                    </div>
                  ))}
                </div>
              </div>
            </section>
          )}
        <section className="section mt-6">
          <Newsletter newsletter={blogPageData} />
        </section>
      </div>
      <ArrowScrollToTop />
    </>
  )
}

export default BlogPost

BlogPost.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const postQuery = graphql`
  query PostBySlug($uid: String!, $locale: String!) {
    prismic {
      blogpost(uid: $uid, lang: $locale) {
        ...Blogpost

        related_posts {
          related_post {
            ... on PRISMIC_Blogpost {
              ...CardPost
            }
          }
        }
      }

      allBlogpages(lang: $locale) {
        edges {
          node {
            related_posts
            share
            newsletter_title
            newsletter_subtitle
            newsletter_placeholder
            newsletter_button
            newsletter_success
            newsletter_image
          }
        }
      }
    }
    site {
      ...SiteInformation
    }
  }
`

const blogPostCss = css`

  .blog__header {
    background: rgb(239,243,254);
    background: linear-gradient(180deg, rgba(239,243,254,1) 0%, rgba(239,243,254,1) 30%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%);

    .image {
      border: 1px solid ${colors.blueHr};
      border-radius: 10px;
      box-shadow: 0 14px 21px 0 rgba(55,68,84,.06);
    }

    .gatsby-image-wrapper {
      border-radius: 10px;
    }

  }

  .blog__main {
    article {
      border-radius: 6px;

      & > p {
        line-height: 2.5rem;
      }

      .box {
        border: 2px solid #FFF;
        background-color: ${colors.borderForm};
        font-style: italic;
        font-weight: 600;
      }

      iframe {
        width: 100%;
        border-radius: 10px;
      }
    }
  }


  .blog__related-posts {
    background: rgb(239,243,254);
    background: linear-gradient(180deg, rgba(239,243,254,1) 0%, rgba(239,243,254,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
  }

  .blog__thumbnail {
    .card {
      box-shadow: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .card-content {
      padding: 1.5rem 0;
    }
  }

  .blog__thumbnail {
    .card-image {
      border-radius: 10px;
      .gatsby-image-wrapper {
        border-radius: 10px;
      }
    }
  }

  .blog__main-content {
    position: relative;
    padding: 1rem;
  }

  .social__share {
    position: absolute;
    left: -75px;
    top: 20px;
    ul {
      text-align: center;
    }
    li {
      margin-top: 10px;
    }
    p {
      transform: rotate(270deg);
      margin-bottom: 40px;
    }
    span {
      font-size: 25px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      color: #FFF;
      background-image: ${colors.orangeGradient};
    }
  }


  @media (max-width: 768px) {
    .social__share {
      top: -50px;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      align-items: center;
      p {
        transform: rotate(360deg);
        padding-left: 0;
        margin-bottom: 0;
      }
      ul {
        display: flex;
      }
      li {
        margin-top: 0;
        margin-left: 10px;
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1410px) {
    .blog__header {
      background: rgb(239,243,254);
      background: linear-gradient(180deg, rgba(239,243,254,1) 0%, rgba(239,243,254,1) 40%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%);
  }
}

  @media (min-width: 490px) and (max-width: 1023px) {
    .blog__header {
      background: rgb(239,243,254);
      background: linear-gradient(180deg, rgba(239,243,254,1) 0%, rgba(239,243,254,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
  }
}

@media (min-width: 375px) and (max-width: 489px) {
  .blog__header {
    background: rgb(239,243,254);
    background: linear-gradient(180deg, rgba(239,243,254,1) 0%, rgba(239,243,254,1) 60%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%);
  }
}

@media (max-width: 374px) {
  .blog__header {
    background: rgb(239,243,254);
    background: linear-gradient(180deg, rgba(239,243,254,1) 0%, rgba(239,243,254,1) 65%, rgba(255,255,255,1) 65%, rgba(255,255,255,1) 100%);
  }
}

@media (min-width: 1024px) {
  .blog__main-content {
    padding: 3rem;
  }
}
`
