import React, { useState } from "react"
import Image from "./image"
import { css } from "@emotion/core"
import { colors } from "../styles/styles"

import axios from "axios"
import { useForm } from "react-hook-form"
import { regEmail } from "../utils/utils"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

function Newletter({ newsletter }) {
  const [formSent, setFormSent] = useState("")
  const { register, handleSubmit, errors } = useForm()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const emails = ['contact@monstock.net', 'com-mktg@monstock.net']

  const onSubmit = async formData => {
    setFormSent({ sending: true })
    const captchaToken = await executeRecaptcha("newsletter")
    axios
      .post("/.netlify/functions/contactForm", { formData, captchaToken, emails })
      .then(function(response) {
        setFormSent(response)
      })
      .catch(function(error) {
        setFormSent({ error: error.message })
      })
  }
  return (
    <div css={newsletterCss}>
      <div className="container px-5 py-5 newletter1">
        <div className="columns is-centered is-vcentered">
          <div className="column is-6">
            {newsletter.newsletter_image && (
              <Image
                sharp={newsletter.newsletter_imageSharp}
                image={newsletter.newsletter_image}
              />
            )}
          </div>
          <div className="column is-1 is-hidden-mobile"></div>
          <div className="column is-4">
            <h5 className="title is-3 has-text-weight-bold">
              {newsletter.newsletter_title && newsletter.newsletter_title}
            </h5>
            <h6 className="is-5 has-text-weight-light">
              {newsletter.newsletter_subtitle && newsletter.newsletter_subtitle}
            </h6>
            {formSent === "" && (
              <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                <p className="control has-icons-right">
                  <input
                    type="email"
                    className="input is-rounded"
                    id="subscribeToNewsletter"
                    name="subscribeToNewsletter"
                    placeholder={newsletter.newsletter_placeholder}
                    ref={register({
                      pattern: regEmail,
                      required: true,
                    })}
                  />
                  <span className="icon is-small is-right">
                    <i className="icon-chevron_right"></i>
                  </span>
                </p>
                {errors.subscribeToNewsletter && (
                  <p className="help is-danger">This field is required</p>
                )}
                <div id=" newsletter-form" className="btn_css">
                <button
                  type="submit"
                  className="button is-rounded button__primary has-text-white mt-4"
                >
                  {newsletter.newsletter_button}
                </button>
                </div>
              </form>
            )}
            {formSent && formSent.sending && (
              <button className="button is-rounded" disabled>
                ...
              </button>
            )}
            {formSent.status && formSent.status === 200 && (
              <button className="button" title="Disabled button" disabled>
                {newsletter.newsletter_success}
              </button>
            )}
            {formSent.status && formSent.status !== 200 && (
              <div className="notification is-rounded is-danger">
                {formSent.error}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Newletter

const newsletterCss = css`
  .container {
    background-color: #f1f7ff;
    border-radius: 10px;
  }
  .newletter1 { 
    margin-top: -7%
  }
  .control.has-icons-right .icon i {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fd7543),
      to(#de4e4e)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  input {
    border-color: ${colors.borderForm};
  }
  .btn_css{
    display:inline-block;
  }
`
